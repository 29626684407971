import { Module, Mutation, Action } from 'vuex-module-decorators';
import { OpenClosedApiModule } from './OpenClosedApiModule';

@Module({
  name: 'PartnerInvoice',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class PartnerInvoiceStoreModule extends OpenClosedApiModule {
  qualifierField = 'documentExternalQualifier';
  types: string[] = [];

  @Action
  async accept(formData: any) {
    try {
      this.context.commit('actionLoading');
      const response = await this.api.post(`/portal/partner/invoices/accept`, formData);
      this.context.commit(formData.documentAccepted ? 'acceptedSuccess' : 'declinedSuccess', response.data.data);
    } catch (e) {
      this.context.commit('actionError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  acceptedSuccess(response: any) {
    this.status = { type: 'success' };
    this.openRecords = this.openRecords.filter((record: any) => record.documentExternalQualifier !== response.documentExternalQualifier);
    this.record = undefined;
  }

  @Mutation
  declinedSuccess(response: any) {
    this.status = { type: 'success' };
    this.openRecords = this.openRecords.map((record: any) => {
      if (record.documentExternalQualifier === response.documentExternalQualifier) {
        record.status = 'beanstandet';
      }
      return record;
    });
    this.record = undefined;
  }

  @Mutation
  actionError() {
    this.status = { type: '' };
  }

  get serviceUrl(): string {
    return '/portal/partner/' + this.currentCustomerNumber + '/invoices';
  }

  get tableModalContent(): object | undefined {
    return {};
  }

  get countOpen() {
    return this.openRecords.filter((invoice: any) => invoice.status === 'offen').length;
  }

  get tableRecords() {
    return this.openRecords;
  }

  get invoiceTypes() {
    return this.types;
  }
}
